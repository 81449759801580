import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

// eslint-disable-next-line import/extensions,import/no-unresolved
import { IssuesQuery } from '../../types/graphql-types';
import { Header1, Header2 } from '../components/headers';
import ContentfulRichText from '../components/contentfulRichText';
import LinkComponent from '../components/linkComponent';

type Props = {
  data: IssuesQuery;
};

const Issues: React.FC<Props> = ({ data }: Props) => {
  const issues =
    data.allContentfulPostTopic?.edges
      .map(({ node }) => node)
      .filter(({ topicId }) => topicId !== 'news') || [];
  return (
    <Layout pathname="/issues">
      <SEO
        title="White Reparations to African People"
        description="Uhuru Solidarity Movement is the reparations organization of white people under the leadership of the African People's Socialist Party"
      />
      <div className="container mx-auto mt-8 md:mt-10">
        <Header1>Issues</Header1>
        <p className="mb-5">Some description about the issues</p>
        {issues.map((issue) => (
          <div className="mb-5">
            <Header2>
              <LinkComponent link={`/issues/${issue.topicId}`}>
                {issue.name}
              </LinkComponent>
            </Header2>
            <ContentfulRichText document={issue.description} />
            <LinkComponent link={`/issues/${issue.topicId}`}>
              Read more
            </LinkComponent>
          </div>
        ))}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Issues {
    allContentfulPostTopic {
      edges {
        node {
          name
          topicId
          description {
            raw
          }
        }
      }
    }
  }
`;

export default Issues;
